import React from "react"
import { Switch, Redirect } from "react-router-dom"
import { RouteWithLayout } from "./components"
import { Minimal as MinimalLayout, Main as MainLayout } from "./layouts"
import * as Commons from "common/common"

import {
  Login as LoginView,
  Home as HomeView,
  NotFound as NotFoundView,
} from "./views"

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={Commons.loginRoute} />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={MinimalLayout}
        path={Commons.loginRoute}
      />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path={Commons.homeRoute}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path={Commons.notFoundRoute}
      />
      <Redirect to={Commons.notFoundRoute} />
    </Switch>
  )
}

export default Routes
